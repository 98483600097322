const React = require('react');
const { bool, shape, string } = require('prop-types');

const NewPricing = require('@calm-landings-library/pricing');
const SgsAccordionSlider = require('@calm-landings-library/sgs-accordion-slider');
const SgsAnimatedImageCards = require('@calm-landings-library/sgs-animated-image-cards');
const SgsAnimationComponent = require('@calm-landings-library/sgs-animation-component');
const SgsCardPlan = require('@calm-landings-library/sgs-card-plan');
const SgsDeviceList = require('@calm-landings-library/sgs-device-list');
const SgsFeesCards = require('@calm-landings-library/sgs-fees-cards');
const SgsGenericHero = require('@calm-landings-library/sgs-generic-hero');
const SgsHero = require('@calm-landings-library/sgs-hero');
const SgsImagesHighlight = require('@calm-landings-library/sgs-images-highlight');
const SgsMediaSlider = require('@calm-landings-library/sgs-media-slider');
const SgsMessageBand = require('@calm-landings-library/sgs-message-band');
const SgsMultiproductSelector = require('@calm-landings-library/sgs-multiproduct-selector');
const SgsNavBar = require('@calm-landings-library/sgs-nav-bar');
const SgsPaymentMethods = require('@calm-landings-library/sgs-payment-methods');
const SgsSlider = require('@calm-landings-library/sgs-slider');
const SgsSpecs = require('@calm-landings-library/sgs-specs');
const SgsTaxesMoneyNow = require('@calm-landings-library/sgs-taxes-money-now');
const SgsMultiproductWrapper = require('@calm-landings-library/sgs-multi-product-wrapper');
const SgsFeesSelector = require('@calm-landings-library/sgs-fee-selector');

const SellersComponent = ({ id, config, type, isMobile, isWebView, theme }) => {
  switch (type) {
    case 'new-pricing':
      return (
        <NewPricing
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-accordion-slider':
      return (
        <SgsAccordionSlider
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-animated-image-cards':
      return (
        <SgsAnimatedImageCards
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-animation-component':
      return (
        <SgsAnimationComponent
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-card-plan':
      return (
        <SgsCardPlan
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-device-list':
      return (
        <SgsDeviceList
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-fees-cards':
      return (
        <SgsFeesCards
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-generic-hero':
      return (
        <SgsGenericHero
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-hero':
      return (
        <SgsHero
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-images-highlight':
      return (
        <SgsImagesHighlight
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-media-slider':
      return (
        <SgsMediaSlider
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-message-band':
      return (
        <SgsMessageBand
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-multiproduct-selector':
      return (
        <SgsMultiproductSelector
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-nav-bar':
      return (
        <SgsNavBar
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-payment-methods':
      return (
        <SgsPaymentMethods
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-slider':
      return (
        <SgsSlider
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-specs':
      return (
        <SgsSpecs
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-taxes-money-now':
      return (
        <SgsTaxesMoneyNow
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-multi-product-wrapper':
      return (
        <SgsMultiproductWrapper
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    case 'sgs-fee-selector':
      return (
        <SgsFeesSelector
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          theme={theme}
        />
      );

    default:
      return null;
  }
};

SellersComponent.propTypes = {
  id: string,
  type: string,
  config: shape({
    lazyMode: string,
  }),
  isMobile: bool,
  isWebView: bool,
  theme: string,
};

module.exports = SellersComponent;
